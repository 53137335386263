<template>
    <!-- Main Footer -->
    <footer
        class="main-footer"
        style="background-image: url(images/background/3.jpg)"
    >
        <div class="auto-container">
            <div class="upper-box">
                <div class="row">
                    <div
                        class="
                            upper-column
                            col-lg-4 col-md-12 col-sm-12
                            d-flex
                            justify-content-start
                        "
                    >
                        <div class="">
                            <div class="footer-logo">
                                <figure class="image">
                                    <a
                                        href="https://play.google.com/store/apps/"
                                        target="_blank"
                                    >
                                        <img
                                            class="cards-logo rounded app-img"
                                            src="/images/google-play.png"
                                            alt=""
                                        />
                                    </a>
                                </figure>
                            </div>
                        </div>
                        <div class="mr-3">
                            <div class="footer-logo">
                                <figure class="image">
                                    <a
                                        href="https://www.apple.com/"
                                        target="_blank"
                                    >
                                        <img
                                            class="
                                                cards-logo
                                                rounded
                                                app-img
                                                apple-logo
                                            "
                                            src="/images/apple-store.png"
                                            alt=""
                                    /></a>
                                </figure>
                            </div>
                        </div>
                    </div>

                    <div class="upper-column col-lg-2 col-md-12 col-sm-12">
                        <div class="footer-logo">
                            <figure class="image">
                                <img
                                    class="cards-logo h-100"
                                    src="/images/visa_master_logo.png"
                                    alt=""
                                />
                            </figure>
                        </div>
                    </div>

                    <div
                        class="
                            upper-column
                            col-lg-3
                            d-lg-block d-md-none d-sm-none
                        "
                    ></div>
                    <!-- Upper column -->
                    <div class="upper-column col-lg-3 col-md-12 col-sm-12">
                        <div class="social-links">
                            <ul class="social-icon-two">
                                <li v-if="settings.social.facebook">
                                    <a
                                        :href="settings.social.facebook"
                                        target="_blank"
                                        ><span class="la la-facebook-f"></span
                                    ></a>
                                </li>
                                <li v-if="settings.social.twitter">
                                    <a
                                        :href="settings.social.twitter"
                                        target="_blank"
                                        ><span class="la la-twitter"></span
                                    ></a>
                                </li>
                                <li v-if="settings.social.google_plus">
                                    <a
                                        :href="settings.social.google_plus"
                                        target="_blank"
                                        ><span class="la la-google-plus"></span
                                    ></a>
                                </li>
                                <li v-if="settings.social.instagram">
                                    <a
                                        :href="settings.social.instagram"
                                        target="_blank"
                                        ><span class="la la-instagram"></span
                                    ></a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!--Footer Bottom-->
        <div class="footer-bottom">
            <div class="auto-container">
                <!--Scroll to top-->
                <div class="scroll-to-top scroll-to-target" data-target="html">
                    <span class="la la-angle-double-up"></span>
                </div>

                <div class="inner-container clearfix">
                    <div class="footer-nav">
                        <ul class="clearfix">
                            <router-link
                                custom
                                to="/home"
                                v-slot="{ href, navigate, isExactActive }"
                            >
                                <li
                                    :class="{
                                        current: isExactActive,
                                    }"
                                >
                                    <a :href="href" @click="navigate">
                                        الرئيسية
                                    </a>
                                </li>
                            </router-link>

                            <router-link
                                custom
                                to="/properties"
                                v-slot="{ href, navigate, isExactActive }"
                            >
                                <li
                                    :class="{
                                        current: isExactActive,
                                    }"
                                >
                                    <a :href="href" @click="navigate">
                                        العقارات
                                    </a>
                                </li>
                            </router-link>

                            <router-link
                                custom
                                to="/privacy-policy"
                                v-slot="{ href, navigate, isExactActive }"
                            >
                                <li
                                    :class="{
                                        current: isExactActive,
                                    }"
                                >
                                    <a :href="href" @click="navigate">
                                        سياسة الخصوصية
                                    </a>
                                </li>
                            </router-link>

                            <router-link
                                custom
                                to="/terms-and-conditions"
                                v-slot="{ href, navigate, isExactActive }"
                            >
                                <li
                                    :class="{
                                        current: isExactActive,
                                    }"
                                >
                                    <a :href="href" @click="navigate">
                                        شروط الإستخدام
                                    </a>
                                </li>
                            </router-link>

                            <router-link
                                custom
                                to="/contact-us"
                                v-slot="{ href, navigate, isExactActive }"
                            >
                                <li
                                    :class="{
                                        current: isExactActive,
                                    }"
                                >
                                    <a :href="href" @click="navigate">
                                        تواصل معنا
                                    </a>
                                </li>
                            </router-link>
                        </ul>
                    </div>

                    <div class="copyright-text">
                        <p>جميع الحقوق محفوظة © | شركة نزهة للخدمات العقارية</p>
                    </div>
                </div>
            </div>
        </div>
    </footer>
    <!-- End Main Footer -->
</template>

<script>
export default {
    name: "site-footer",
    data() {
        return {
            settings: {
                social: {},
            },
        };
    },
    mounted() {
        this.$axios.get("settings").then((res) => {
            this.settings = res.data.settings;
        });
    },
};
</script>


<style scoped>
.cards-logo {
    background-color: #ccc;
}

.app-img {
    height: 36px !important;
}

.apple-logo {
    padding-right: 10px;
    padding-left: 10px;
}
</style>