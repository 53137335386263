import noty from 'noty'

export default {
    options: {},
    positions: ['top', 'topLeft', 'topCenter', 'topRight', 'center', 'centerLeft', 'centerRight', 'bottom', 'bottomLeft', 'bottomCenter', 'bottomRight'],
    install(app, options) {
        this.options = options;
        app.config.globalProperties.$noty = this;
    },
    show(variable = false) {
        this.options.type = 'info';

        this.handleIncommingVariabe(variable)
        new noty(this.options).show();
    },
    error(variable = false) {
        this.options.type = 'error';

        this.handleIncommingVariabe(variable)
        new noty(this.options).show();
    },
    success(variable = false) {
        this.options.type = 'success';

        this.handleIncommingVariabe(variable)
        new noty(this.options).show();
    },
    warning(variable = false) {
        this.options.type = 'warning';

        this.handleIncommingVariabe(variable)
        new noty(this.options).show();
    },
    handleIncommingVariabe(variable) {
        switch (typeof variable) {
            case 'string':
                this.options = {
                    ...this.options,
                    text: variable
                }
                break;
            case 'object':
                this.options = { ...this.options, ...variable }
                break;
        }
    },
    in(position = 'topCenter') {
        if (this.positions.includes(position)) {
            this.options.layout = position;
        } else {
            this.options.layout = 'topCenter';
        }
        return this;
    }
}