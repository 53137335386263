
export default {
    opacity: 0,
    display: 'none', //for website loading
    install(app) {
        app.config.globalProperties.$loading = this
    },
    show() {
        this.opacity = 0.7;
        this.display = "block";
        this['z-index'] = 2000;
        this.apply()
    },
    hide(after = false) {
        this.opacity = 0;
        this.display = 'none';
        this['z-index'] = -999;
        if (!after) {
            this.apply()
        } else {
            setTimeout(() => {
                this.apply()
            }, after);
        }
    },
    apply() {
        let loadingBox = document.getElementById('loading-box');

        let websitePreloader = document.getElementsByClassName('preloader')[0];

        if (loadingBox) {
            loadingBox.style.opacity = this.opacity;
            loadingBox.style['z-index'] = this['z-index'];
        }

        if (websitePreloader) {
            websitePreloader.style.display = this.display;
        }
    }
};
