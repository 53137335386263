<template>
    <!-- Hidden Bar -->
    <section class="hidden-bar">
        <div class="dashboard-inner">
            <div @click="toggleSideMenu" class="cross-icon">
                <span class="pe-7s-close-circle"></span>
            </div>
            <ul class="navigation">
                <router-link
                    custom
                    to="/my/dashboard"
                    v-slot="{ href, navigate, isExactActive }"
                >
                    <li :class="{ active: isExactActive }">
                        <a :href="href" @click="navigate">
                            <i class="pe-7s-culture"></i> الرئيسية
                        </a>
                    </li>
                </router-link>

                <router-link
                    custom
                    to="/my/messages"
                    v-slot="{ href, navigate, isExactActive }"
                >
                    <li :class="{ active: isExactActive }">
                        <a :href="href" @click="navigate">
                            <i class="pe-7s-mail"></i> الرسائل<span
                                v-if="unseen > 0"
                                class="tag"
                                >{{ unseen }}</span
                            >
                        </a>
                    </li>
                </router-link>

                <router-link
                    custom
                    to="/my/my-notifications"
                    v-slot="{ href, navigate, isExactActive }"
                >
                    <li :class="{ active: isExactActive }">
                        <a :href="href" @click="navigate">
                            <i class="pe-7s-bell"></i> الإشعارات
                        </a>
                    </li>
                </router-link>

                <router-link
                    v-if="
                        [
                            'advertiser',
                            'employee',
                            'admin',
                            'super-admin',
                        ].includes(userData.type)
                    "
                    custom
                    to="/my/bookings"
                    v-slot="{ href, navigate, isExactActive }"
                >
                    <li :class="{ active: isExactActive }">
                        <a :href="href" @click="navigate">
                            <i class="pe-7s-date"></i> الطلبات الجديدة
                        </a>
                    </li>
                </router-link>

                <router-link
                    v-if="
                        [
                            'advertiser',
                            'employee',
                            'admin',
                            'super-admin',
                        ].includes(userData.type)
                    "
                    custom
                    to="/my/all-bookings"
                    v-slot="{ href, navigate, isExactActive }"
                >
                    <li :class="{ active: isExactActive }">
                        <a :href="href" @click="navigate">
                            <i class="pe-7s-date"></i> جدول الطلبات
                        </a>
                    </li>
                </router-link>

                <router-link
                    custom
                    to="/my/reservations"
                    v-slot="{ href, navigate, isExactActive }"
                >
                    <li :class="{ active: isExactActive }">
                        <a :href="href" @click="navigate">
                            <i class="pe-7s-way"></i> الحجوزات
                        </a>
                    </li>
                </router-link>

                <router-link
                    v-if="
                        [
                            'advertiser',
                            'employee',
                            'admin',
                            'super-admin',
                        ].includes(userData.type)
                    "
                    custom
                    to="/my/invoices"
                    v-slot="{ href, navigate, isExactActive }"
                >
                    <li :class="{ active: isExactActive }">
                        <a :href="href" @click="navigate">
                            <i class="pe-7s-note2"></i> الأرباح
                        </a>
                    </li>
                </router-link>

                <router-link
                    v-if="
                        [
                            'advertiser',
                            'employee',
                            'admin',
                            'super-admin',
                        ].includes(userData.type)
                    "
                    custom
                    to="/my/bank-transferes"
                    v-slot="{ href, navigate, isExactActive }"
                >
                    <li :class="{ active: isExactActive }">
                        <a :href="href" @click="navigate">
                            <i class="pe-7s-cash"></i> التحويلات البنكية
                        </a>
                    </li>
                </router-link>

                <router-link
                    custom
                    to="/my/favourite-properties"
                    v-slot="{ href, navigate, isExactActive }"
                >
                    <li :class="{ active: isExactActive }">
                        <a :href="href" @click="navigate">
                            <i class="pe-7s-like2"></i> الإعلانات المفضلة
                        </a>
                    </li>
                </router-link>

                <router-link
                    v-if="
                        [
                            'advertiser',
                            'employee',
                            'admin',
                            'super-admin',
                        ].includes(userData.type)
                    "
                    custom
                    to="/my/new-property"
                    v-slot="{ href, navigate, isExactActive }"
                >
                    <li :class="{ active: isExactActive }">
                        <a :href="href" @click="navigate">
                            <i class="pe-7s-plus"></i> إضافة إعلان
                        </a>
                    </li>
                </router-link>

                <router-link
                    v-if="
                        [
                            'advertiser',
                            'employee',
                            'admin',
                            'super-admin',
                        ].includes(userData.type)
                    "
                    custom
                    to="/my/properties"
                    v-slot="{ href, navigate, isExactActive }"
                >
                    <li :class="{ active: isExactActive }">
                        <a :href="href" @click="navigate">
                            <i class="pe-7s-diamond"></i> الإعلانات
                        </a>
                    </li>
                </router-link>

                <router-link
                    custom
                    to="/my/profile"
                    v-slot="{ href, navigate, isExactActive }"
                >
                    <li :class="{ active: isExactActive }">
                        <a :href="href" @click="navigate">
                            <i class="pe-7s-user"></i> الملف الشخصى
                        </a>
                    </li>
                </router-link>
                <li>
                    <a href="#" @click.prevent="logout"
                        ><i class="pe-7s-back-2"></i>تسجيل الخروج</a
                    >
                </li>
            </ul>
        </div>
    </section>
    <!--End Hidden Bar -->
</template>

<script>
import chat from "@/plugins/firebase";

export default {
    name: "control-side-menu",
    data() {
        return {
            chatObj: {},
        };
    },
    async mounted() {
        Object.assign(this.chatObj, await chat()).prepare(
            this.chatObj,
            null,
            this.$store
        );
    },
    methods: {
        toggleSideMenu() {
            this.$store.commit("toggleSideMenu");
        },
        logout() {
            this.$store.dispatch("logout");
        },
    },
    computed: {
        unseen() {
            return this.$store.state.unseen;
        },
        userData() {
            return this.$store.state.userData;
        },
    },
};
</script>