import { createApp } from 'vue'
import App from '../views/website/layouts/Layout.vue'
import router from '../router/website'
import store from '../store/website'
import VueAxios from '@/plugins/axios'
import VueNoty from '@/plugins/noty'
import myLoading from '@/plugins/loading'
import googleMap from '@/plugins/googleMaps'

store.commit('initialize');

export default createApp(App)
    .use(myLoading)
    .use(VueNoty, {
        timeout: 3000,
        progressBar: true,
        killer: true,
        theme: "metroui"
    })
    .use(VueAxios)
    .use(store)
    .use(router)
    .use(googleMap, {
        apiKey: 'AIzaSyBdeypWd-WdSz_n6UlNSl7dSgqjBC6T17g',
    })
    .mount('#app')
