import { createStore } from 'vuex'
import app from '../index/website'
import firebase from 'firebase/app'
import 'firebase/auth';
import 'firebase/messaging';

let firebaseConfig = {
  apiKey: "AIzaSyBdeypWd-WdSz_n6UlNSl7dSgqjBC6T17g",
  authDomain: "wamda-e3809.firebaseapp.com",
  databaseURL: "https://wamda-e3809-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "wamda-e3809",
  storageBucket: "wamda-e3809.appspot.com",
  messagingSenderId: "731143289849",
  appId: "1:731143289849:web:34949871ea537a30381b28"
}

let messagingPublicKey = "BPEHiWjjOPfmeNmy_IVW6yHfSiCAVXHG15go2imPevuxRTZFziXgnhasmAA9oR7kq0MhmengMqktJ6oD3M3L62k";

export default createStore({
  state: {
    loggedIn: false,
    userData: {},
    sideMenu: false,
    unseen: 0,
    unseenCounter: true,
    notifications: {
      unread: 0,
      data: [],
    },
    storage: sessionStorage

  },
  mutations: {
    initialize(state) {
      state.loggedIn = [localStorage.getItem('loggedIn'), sessionStorage.getItem('loggedIn')].includes('true');

      if (localStorage.getItem('userData')) {
        state.userData = JSON.parse(localStorage.getItem('userData'));

        state.notifications = JSON.parse(localStorage.getItem('notifications'));
        state.storage = localStorage;
      } else if (sessionStorage.getItem('userData')) {
        state.storage = sessionStorage;
        state.userData = JSON.parse(sessionStorage.getItem('userData'));

        state.notifications = JSON.parse(sessionStorage.getItem('notifications'))
      }

      firebase.initializeApp(firebaseConfig);
      if (firebase.messaging.isSupported()) {
        firebase.messaging().onMessage((payload) => {
          state.notifications.data.push({ title: payload.notification.title, body: payload.notification.body });
          state.notifications.unread++;

          state.storage.setItem('notifications', JSON.stringify(state.notifications));
          app.$noty.in("topCenter").show(payload.notification.title + '<br />' + payload.notification.body)
        })
      }

    },
    login(state, data, remember = false) {
      let storage = remember ? localStorage : sessionStorage

      state.loggedIn = true;
      storage.setItem('loggedIn', 'true');
      state.userData = data;
      storage.setItem('userData', JSON.stringify(data));
      storage.setItem('notifications', JSON.stringify(state.notifications));
      state.storage = storage;
    },
    logout(state) {
      state.loggedIn = false;
      state.userData = {};
      localStorage.clear();
      sessionStorage.clear();
    },
    toggleSideMenu(state) {
      state.sideMenu = !state.sideMenu;
      localStorage.setItem('sideMenu', state.sideMenu);
    },
    setUnseen(state, unseen = 0) {
      state.unseen = unseen
    },
    setUnseenCounter(state, status = true) {
      state.unseenCounter = status
    }
  },
  actions: {
    login(context, credentials) {
      app.$axios.get("../sanctum/csrf-cookie").then(() => {
        app.$axios.post("/login", credentials).then((response) => {

          firebase.auth().setPersistence(credentials.remember ? firebase.auth.Auth.Persistence.LOCAL : firebase.auth.Auth.Persistence.SESSION).then(() => {
            firebase.auth().signInWithCustomToken(response.data['firebase-token']).then(({ user }) => {
              // here we can retrive refresh token
            })
          })
          if (firebase.messaging.isSupported()) {
            firebase.messaging().getToken({ vapidKey: messagingPublicKey }).then((registerationToken) => {
              if (registerationToken) {
                app.$axios.post('add-fcm-token', { token: registerationToken, type: 'web' }).then(() => {
                  // fcm token set successfully
                  this.storage.setItem('userData.fcm_token', registerationToken);
                })
              }
            })
          }

          context.commit("login", response.data, credentials.remember);

          app.$noty.in("topCenter").success("تم تسجيل الدخول بنجاح");

          setTimeout(() => {
            let route = ['super-admin', 'admin', 'employee'].includes(context.state.userData.type) ? '/admin/dashboard' : '/home';

            app.$router.push(route);
          }, 2000);
        });
      });
    },
    register(context, data) {
      app.$axios.get("../sanctum/csrf-cookie").then(() => {
        app.$axios.post("/register", (({
          name,
          email,
          password,
          transfer_data,
          image,
          mobile,
          country,
          country_code,
          gender,
          type
        }) => ({
          name,
          email,
          password,
          transfer_data,
          image,
          mobile,
          country,
          country_code,
          gender,
          type
        }))(data)).then((response) => {
          context.commit("login", response.data);

          app.$noty.in("topCenter").success("تم تسجيل حسابك");

          let route = ['super-admin', 'admin', 'employee'].includes(context.state.userData.type) ? '/admin/dashboard' : '/home';

          app.$router.push(route);
        });
      });
    },
    async logout(context) {
      await app.$axios.post('/logout').then(() => {

        context.commit('logout');
        window.location.href = '/home';
      }).catch(() => {
        context.commit('logout');
        window.location.href = '/home';
        // app.$noty.error('issue in logout');
      })

      firebase.auth().signOut();
      if (firebase.messaging.isSupported()) {
        firebase.messaging().deleteToken();
      }
    },
    guardDashboard(context) {
      if (!context.state.loggedIn) {
        app.$noty.error('يجب تسجيل الدخول أولا');
        window.location.href = '/login';
      }
    },
    async emailVerifiedGuard(context, updateFirst = false) {
      if (updateFirst && !context.state.userData.email_verified_at && !['super-admin', 'admin', 'employee'].includes(context.state.userData.type)) {
        context.dispatch('updateUserData').then(() => {
          if (!context.state.userData.email_verified_at) {
            app.$noty.warning('يجب أن تقوم بتفعيل إيميلك');
            app.$router.push('/my/profile');
          }
        })
      } else if (!context.state.userData.email_verified_at) {
        app.$noty.warning('يجب أن تقوم بتفعيل إيميلك');
        app.$router.push('/my/profile');
      }
    },
    resendVerificationEmail() {
      app.$axios.post('/email/resend');
    },
    async updateUserData(context, data = false) {
      if (data) {
        context.commit('login', data, localStorage.getItem('loggedIn'))
      } else {
        return await app.$axios.get('../sanctum/csrf-cookie').then(async () => {
          return await app.$axios.get('/profile').then((response) => {
            context.commit('login', response.data)
          })
        })
      }
    },
    setUnreadNotes(context) {
      context.state.notifications = {
        unread: 0,
        data: [],
      };

      context.state.storage.setItem('notifications', JSON.stringify({
        unread: 0,
        data: [],
      }));
    }
  }
});
