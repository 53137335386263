<template>
    <!-- Main Header-->
    <header class="main-header">
        <div class="main-box clearfix">
            <!-- Logo Box -->
            <div class="logo-box">
                <div class="logo">
                    <a href="/"
                        ><img src="/images/logo-small.png" alt="" title=""
                    /></a>
                </div>
            </div>

            <!-- Upper Right-->
            <div class="upper-right">
                <ul class="clearfix">
                    <li class="dropdown option-box">
                        <a
                            class="dropdown-toggle"
                            data-toggle="dropdown"
                            aria-expanded="false"
                        >
                            <span class="fa-stack fa-3x" :data-count="notifications.unread">
                                <i class="la la-bell-o fa-stack-2x"></i>
                                <i
                                    class="fa fa-bell fa-stack-1x fa-inverse"
                                ></i>
                            </span>
                        </a>
                        <div class="dropdown-menu notes">
                            <template
                                v-for="(note, index) in notifications.data"
                                :key="index"
                            >
                                <router-link
                                    class="dropdown-item"
                                    to="/my/my-notifications"
                                >
                                    <p>
                                        {{ note.title }}:
                                        <span class="accent">
                                            {{ note.body }}</span
                                        >.
                                    </p>
                                </router-link>
                            </template>
                            <router-link
                                class="bordered border-top d-block w-100  text-center"
                                to="/my/my-notifications"
                            >
                                كل التنبيهات
                            </router-link>
                        </div>
                    </li>
                    <li class="dropdown option-box">
                        <a
                            class="dropdown-toggle"
                            data-toggle="dropdown"
                            aria-expanded="false"
                        >
                            <img :src="imageUrl" alt="avatar" class="thumb" />{{
                                name
                            }}</a
                        >
                        <div class="dropdown-menu">
                            <router-link
                                class="dropdown-item"
                                to="/my/messages"
                            >
                                مراسلات الإدارة
                                <span v-if="unseen > 0" class="tag">{{
                                    unseen
                                }}</span>
                            </router-link>
                            <router-link
                                class="dropdown-item"
                                to="/my/my-notifications"
                            >
                                الإشعارات
                            </router-link>
                            <router-link class="dropdown-item" to="/my/profile"
                                >الملف الشخصى</router-link
                            >
                        </div>
                    </li>
                    <li class="submit-property">
                        <router-link
                            v-if="
                                [
                                    'advertiser',
                                    'employee',
                                    'admin',
                                    'super-admin',
                                ].includes(type)
                            "
                            to="/my/new-property"
                            class="theme-btn btn-style-one"
                            >إضافة إعلان <i class="pe-7s-up-arrow"></i
                        ></router-link>
                    </li>
                    <li class="nav-toggler">
                        <button
                            @click="toggleSideMenu"
                            id="sidemenu-button"
                            class="toggler-btn nav-btn"
                        >
                            <span class="bar bar-one"></span
                            ><span class="bar bar-two"></span
                            ><span class="bar bar-three"></span>
                        </button>
                    </li>
                </ul>
            </div>
        </div>
        <!--End Header Lower-->
    </header>
    <!--End Main Header -->
</template>

<script>
export default {
    name: "site-header",
    mounted() {
        if (this.sideMenu) {
            document
                .getElementsByTagName("body")[0]
                .classList.add("side-content-visible");

            document.getElementById("sidemenu-button").classList.add("active");
        }
    },
    methods: {
        toggleSideMenu() {
            this.$store.commit("toggleSideMenu");
        },
        logout() {
            this.$store.dispatch("logout");
        },
    },
    computed: {
        name() {
            return this.$store.state.userData.name;
        },
        imageUrl() {
            return this.$store.state.userData.image_url;
        },
        sideMenu() {
            return this.$store.state.sideMenu;
        },
        unseen() {
            return this.$store.state.unseen;
        },
        type() {
            return this.$store.state.userData.type;
        },
        notifications() {
            return this.$store.state.notifications;
        },
    },
};
</script>

<style>
.logo img {
    width: 200px;
}

.side-content-visible .main-header .main-box {
    margin-right: 0;
}

.fa-stack[data-count]:after {
    position: absolute;
    right: 0%;
    top: 1%;
    content: attr(data-count);
    font-size: 70%;
    padding: 0.6em;
    border-radius: 999px;
    line-height: 0.75em;
    color: white;
    background: rgba(255, 0, 0, 0.85);
    text-align: center;
    min-width: 2em;
    font-weight: bold;
}

.fa-stack-2x {
    font-size: 40px !important;
}

.notes {
    width: 280px;
    text-align: right;
    direction: rtl;
}
</style>