import axios from "axios";
import Noty from '@/plugins/noty';

axios.defaults.withCredentials = true
axios.defaults.baseURL = process.env.VUE_APP_LOCAL ? process.env.VUE_APP_API_BASE_URL_LOCAL : process.env.VUE_APP_API_BASE_URL


export default {
    install(app, options) {

        axios.interceptors.request.use(function (config) {
            app.config.globalProperties.$loading.show();
            return config;
        });

        axios.interceptors.response.use(function (response) {

            app.config.globalProperties.$loading.hide();

            switch (response.status) {
                case 200:
                    if (response.data.message) {
                        Noty.in("topCenter").success(response.data.message);
                    }
                    break;

                default:
                    if (response.data.message) {
                        Noty.in("topCenter").success(response.data.message);
                    }
                    break;
            }

            return response;

        }, function (error) {

            app.config.globalProperties.$loading.hide();

            switch (error.response.status) {
                case 422:

                    if (error.response.data.errors.length) {
                        Noty.in("topCenter").error(error.response.data.errors.join('<br />'));
                    } else {
                        Noty.in("topCenter").error("status 422 but no errors array provided");
                    }

                    break;

                case 401:
                    if (error.response.data.message == "Unauthenticated.") {

                        Noty.in("topCenter").error('يجب تسجيل الدخول أولا لإتمام العملية');
                        window.open('/login')
                        // app.state.dispatch('logout');
                    }

                    break;

                default:

                    if (error.response.data.message) {
                        Noty.in("topCenter").error(error.response.data.message);
                    } else {
                        Noty.in("topCenter").error("unknown error");
                    }

                    break;
            }

            return Promise.reject(error);
        });

        app.config.globalProperties.$axios = Object.assign(axios, options)


    }
}

