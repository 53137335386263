<template>
  <router-view v-slot="{ Component }">
    <transition mode="out-in">
      <keep-alive>
        <component :is="Component" />
      </keep-alive>
    </transition>
  </router-view>
</template>
<script>
export default {
  name: "Layout",
};
</script>

<style>
@import "./../../assets/vuejs-noty.css";

form span[role="alert"] {
  color: #ff4444;
  font-size: 13px;
  display: block !important;
}
</style>