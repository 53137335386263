import store from '../store/website'

import { createRouter, createWebHistory } from 'vue-router'
import ControlLayout from '../views/website/layouts/ControlLayout.vue'
import WebsiteLayout from '../views/website/layouts/WebsiteLayout.vue'

const routes = [
    {
        path: '/my',
        name: 'control',
        component: ControlLayout,
        beforeEnter: () => {
            store.dispatch('guardDashboard');
        },
        children: [
            {
                path: '',
                name: 'my.dashboard',
                component: () => import('../views/website/pages/my/Dashboard.vue'),
                alias: 'dashboard',
                beforeEnter: (to, from) => {
                    store.dispatch('emailVerifiedGuard', !['login', 'register', 'my.profile'].includes(from.name));
                }
            },
            {
                path: 'profile',
                name: 'my.profile',
                component: () => import('../views/website/pages/my/Profile.vue'),
            },
            {
                path: 'messages',
                name: 'my.messages',
                component: () => import('../views/website/pages/my/Messages.vue'),
            },
            {
                path: 'new-property',
                name: 'my.new-property',
                component: () => import('../views/website/pages/my/NewProperty.vue'),
            },
            {
                path: 'edit-property/:id',
                name: 'my.edit-property',
                component: () => import('../views/website/pages/my/EditProperty.vue'),
            },
            {
                path: 'properties',
                name: 'my.properties',
                component: () => import('../views/website/pages/my/Properties.vue'),
            },
            {
                path: 'favourite-properties',
                name: 'my.favourite-properties',
                component: () => import('../views/website/pages/my/FavouriteProperties.vue'),
            },
            {
                path: 'my-notifications',
                name: 'my.my-notifications',
                component: () => import('../views/website/pages/my/Notifications.vue'),
            },
            {
                path: 'bookings',
                name: 'my.bookings',
                component: () => import('../views/website/pages/my/Bookings.vue'),
            },
            {
                path: 'all-bookings',
                name: 'my.all-bookings',
                component: () => import('../views/website/pages/my/AllBookings.vue'),
            },
            {
                path: 'reservations',
                name: 'my.reservations',
                component: () => import('../views/website/pages/my/Reservations.vue'),
            },
            {
                path: 'invoices',
                name: 'my.invoices',
                component: () => import('../views/website/pages/my/Invoices.vue'),
            },
            {
                path: 'invoice/:id',
                name: 'my.invoice',
                component: () => import('../views/website/pages/my/Invoice.vue'),
            },
            {
                path: 'bank-transferes',
                name: 'my.bank-transferes',
                component: () => import('../views/website/pages/my/BankTransferes.vue'),
            },
        ]
    },
    {
        path: '/',
        name: 'website',
        component: WebsiteLayout,
        children: [
            {
                path: '',
                name: 'website.home',
                component: () => import('../views/website/pages/Home.vue'),
                alias: '/home'
            },
            {
                path: '/property/:id',
                name: 'website.property',
                component: () => import('../views/website/pages/Property.vue')
            },
            {
                path: '/rent-property/:id',
                name: 'website.rent-property',
                component: () => import('../views/website/pages/RentProperty.vue')
            },
            {
                path: '/properties',
                name: 'website.properties',
                component: () => import('../views/website/pages/Properties.vue')
            },
            {
                path: '/login',
                name: 'login',
                component: () => import('../views/website/pages/Auth/Login.vue')
            },
            {
                path: '/register',
                name: 'register',
                component: () => import('../views/website/pages/Auth/Register.vue')
            },
            {
                path: '/reset-password',
                name: 'reset-password',
                component: () => import('../views/website/pages/Auth/ResetPassword.vue')
            },
            {
                path: '/contact-us',
                name: 'website.contact-us',
                component: () => import('../views/website/pages/ContactUs.vue'),
            },
            {
                path: '/terms-and-conditions',
                name: 'website.terms-and-conditions',
                component: () => import('../views/website/pages/TermsAndConditions.vue'),
            },
            {
                path: '/privacy-policy',
                name: 'website.privacy-policy',
                component: () => import('../views/website/pages/PrivacyPolicy.vue'),
            },
        ]
    },
    {
        path: '/admin/:path(.*)?',
        beforeEnter: (to) => {
            window.location.href = to.href;
        }
    },
    {
        path: '/:url(.*)',
        // 404
    }
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

export default router
