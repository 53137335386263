<template>
    <!-- Main Header-->
    <header class="main-header header-style-one">
        <!--Header Top-->
        <div class="header-top">
            <div class="auto-container">
                <div class="inner-container clearfix">
                    <div class="top-left">
                        <ul class="contact-list clearfix">
                            <li>
                                <i class="la la-home"></i>
                                {{ settings.addresses[0] }}
                            </li>
                            <li>
                                <i class="la la-envelope-o"></i
                                ><a href="#">{{ settings.emails[0] }}</a>
                            </li>
                        </ul>
                    </div>
                    <div class="top-right">
                        <div class="btn-box">
                            <div class="top-left" v-if="loggedIn">
                                <ul class="contact-list clearfix p-0">
                                    <li>
                                        <a
                                            class="theme-btn btn-style-four"
                                            href="/my"
                                            >لوحة التحكم</a
                                        >
                                    </li>

                                    <li class="mt-2" >
                                        <a class="" href="/my/messages"
                                            ><i class="la la-comments"></i
                                        ></a>
                                    </li>
                                </ul>
                            </div>
                            <router-link
                                v-else
                                class="theme-btn btn-style-four"
                                to="/login"
                                >تسجيل الدخول</router-link
                            >
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- End Header Top -->

        <!-- Header Lower -->
        <div class="header-lower">
            <div class="main-box">
                <div class="auto-container">
                    <div class="inner-container clearfix">
                        <div class="logo-box">
                            <div class="logo">
                                <router-link to="/"
                                    ><img
                                        class="main-logo"
                                        src="/images/logo.png"
                                        alt=""
                                        title=""
                                /></router-link>
                            </div>
                        </div>

                        <div class="nav-outer">
                            <!-- Main Menu -->
                            <nav
                                class="main-menu navbar-expand-md navbar-light"
                            >
                                <div class="navbar-header">
                                    <!-- Toggle Button -->
                                    <button
                                        class="navbar-toggler"
                                        type="button"
                                        data-toggle="collapse"
                                        data-target="#navbarSupportedContent"
                                        aria-controls="navbarSupportedContent"
                                        aria-expanded="false"
                                        aria-label="Toggle navigation"
                                    >
                                        <span class="icon flaticon-menu"></span>
                                    </button>
                                </div>

                                <div
                                    class="collapse navbar-collapse clearfix"
                                    id="navbarSupportedContent"
                                >
                                    <navigation-links />
                                </div>
                            </nav>
                            <!-- Main Menu End-->

                            <!-- Main Menu End-->
                            <!-- <div class="outer-box clearfix">
                                <div class="search-box-outer">
                                    <div class="dropdown">
                                        <button
                                            class="search-box-btn dropdown-toggle"
                                            type="button"
                                            id="dropdownMenu3"
                                            data-toggle="dropdown"
                                            aria-haspopup="true"
                                            aria-expanded="false"
                                        >
                                            <span class="la la-search"></span>
                                        </button>
                                        <ul
                                            class="dropdown-menu pull-right search-panel"
                                            aria-labelledby="dropdownMenu3"
                                        >
                                            <li class="panel-outer">
                                                <div class="form-container">
                                                    <form
                                                        method="post"
                                                        action="blog.html"
                                                    >
                                                        <div class="form-group">
                                                            <input
                                                                type="search"
                                                                name="field-name"
                                                                value=""
                                                                placeholder="Search Here"
                                                                required
                                                            />
                                                            <button
                                                                type="submit"
                                                                class="search-btn"
                                                            >
                                                                <span
                                                                    class="la la-search"
                                                                ></span>
                                                            </button>
                                                        </div>
                                                    </form>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div> -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!--End Header Lower-->

        <!-- Sticky Header  -->
        <div class="sticky-header">
            <div class="auto-container clearfix">
                <!--Logo-->
                <div class="logo pull-left">
                    <a href="index.html" title=""
                        ><img
                            class="main-logo"
                            src="/images/logo-small.png"
                            alt=""
                            title=""
                    /></a>
                </div>
                <!--Right Col-->
                <div class="pull-right">
                    <!-- Main Menu -->
                    <nav class="main-menu">
                        <div class="navbar-collapse show collapse clearfix">
                            <navigation-links />
                        </div>
                    </nav>
                    <!-- Main Menu End-->
                </div>
            </div>
        </div>
        <!-- End Sticky Menu -->
    </header>
    <!--End Main Header -->
</template>

<script>
import NavigationLinks from "./NavigationLinks.vue";
export default {
    name: "site-header",
    data() {
        return {
            settings: {
                addresses: [],
                emails: [],
            },
        };
    },
    components: {
        "navigation-links": NavigationLinks,
    },
    mounted() {
        this.$axios.get("settings").then((res) => {
            this.settings = res.data.settings;
        });
    },
    computed: {
        user() {
            return this.$store.state.userData;
        },
        loggedIn() {
            return this.$store.state.loggedIn;
        },
    },
};
</script>

<style scoped>
.main-logo {
    height: 60px !important;
}
</style>

<style>
.header-style-one .header-lower .logo-box:before {
    background-color: #f6f6f6 !important;
}

.header-style-one .header-lower .logo:after {
    border-right: 70px solid #f6f6f6 !important;
}
</style>