<template>
  <div>
    <site-header />
    <router-view v-slot="{ Component }">
      <transition mode="out-in">
        <keep-alive>
          <component :is="Component" />
        </keep-alive>
      </transition>
    </router-view>
    <site-footer />
  </div>
</template>
<script>
import Header from "./Header.vue";
import Footer from "./Footer.vue";

export default {
  name: "WebsiteLayout",
  components: {
    "site-header": Header,
    "site-footer": Footer,
  },
};
</script>

<style>
@import "../assets/css/style.css";
@import "../assets/css/responsive.css";
</style>