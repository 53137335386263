<template>
    <ul class="navigation clearfix">
        <router-link
            custom
            to="/home"
            v-slot="{ href, navigate, isExactActive }"
        >
            <li
                :class="{
                    current: isExactActive,
                }"
            >
                <a :href="href" @click="navigate"> الرئيسية </a>
            </li>
        </router-link>

        <router-link
            custom
            to="/properties"
            v-slot="{ href, navigate, isExactActive }"
        >
            <li
                :class="{
                    current: isExactActive,
                }"
            >
                <a :href="href" @click="navigate"> العقارات </a>
            </li>
        </router-link>

        <router-link
            custom
            to="/contact-us"
            v-slot="{ href, navigate, isExactActive }"
        >
            <li
                :class="{
                    current: isExactActive,
                }"
            >
                <a :href="href" @click="navigate"> تواصل معنا </a>
            </li>
        </router-link>
    </ul>
</template>

<script>
export default {
    name: "navigation-links",
};
</script>