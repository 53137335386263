<template>
  <div>
    <control-header />
    <control-side-menu />
    <router-view v-slot="{ Component }">
      <transition mode="out-in">
        <keep-alive>
          <component :is="Component" />
        </keep-alive>
      </transition>
    </router-view>
  </div>
</template>
<script>
import Header from "./ControlHeader.vue";
import SideMenu from "./ControlSideMenu.vue";

export default {
  name: "ControlLayout",
  components: {
    "control-header": Header,
    "control-side-menu": SideMenu,
  },
};
</script>