import firebase from 'firebase/app'
import 'firebase/auth';
import 'firebase/database';
import moment from "moment"

let chat = {
    ...firebase,

    initialized: false,
    userData: null,
    partnerUid: null,
    timeOffset: null,
    loadedMessages: [],
    unseen: 0,
    unseenCounter: true,

    async initialize(uid = null) {
        this.setUserData();

        this.partnerUid = uid;

        this.initialized = true;

        return this;
    },
    prepare(chatObj, chatModalObject = undefined, unseenWrapper = false) {
        chatObj.setMessageTimeCounter(chatModalObject);
        if (unseenWrapper) {
            chatObj.initializeUnseen(chatModalObject, unseenWrapper);
        }


        return this
    },
    setMessageTimeCounter(chatModalObject = false) {
        this.messagesTimeinterval = setInterval(() => {
            this.loadedMessages.forEach((message, index) => {
                this.loadedMessages[index] = this.setDateTime(
                    message
                );
            });
        }, 60000);

        if (chatModalObject) {
            chatModalObject.on("hide.bs.modal", () => {
                this.destruct()
            });
        }
    },
    async sendMessage(message) {
        let preparedMessage = {
            sender_id: this.userData.id,
            sender_name: this.userData.name,
            text: message,
            timestamp: this.database.ServerValue.TIMESTAMP
        };

        await this.getMessagesRef().push(preparedMessage)

        this.updateOpenChats(preparedMessage);
    },
    setMessagesEventListener(handler, limit = 15, event = 'child_added', emptyFirst = true) {
        emptyFirst && (this.loadedMessages = [])
        this.getMessagesRef().off(event, handler);
        this.getMessagesRef().limitToLast(limit).on(event, handler);
    },
    getMessagesRef() {
        let ref = 'managers-group-chat'
        if (['user', 'advertiser'].includes(this.userData.type)) {
            // means that user or advertizer wants to chat with support
            ref = this.userData.id + '/chat';
        } else {
            /*
            means an employee or admin or manager 
            wants to chat with managers group or 
            with a specific user
            */

            if (this.partnerUid != null) {
                // he wants to chat with a specific user
                ref = this.partnerUid + '/chat';
            }
        }

        return firebase.database().ref(ref);
    },
    updateOpenChats(preparedMessage) {
        let ref = 'open-chats/'
        if (['user', 'advertiser'].includes(this.userData.type)) {
            // means that user or advertizer wants to chat with support
            ref += this.userData.id;
            preparedMessage = Object.assign(preparedMessage, {
                name: this.userData.name,
                image: this.userData.image_url,
                id: parseInt(this.userData.id)
            });

            firebase.database().ref(ref).set(preparedMessage);

        } else if (this.partnerUid != null) {
            /*
            means an employee or admin or manager 
            wants to chat with a specific user
            */

            ref += this.partnerUid;
            preparedMessage = Object.assign(preparedMessage, {
                name: this.partnerName,
                image: this.partnerImage,
                id: parseInt(this.partnerUid)
            });

            firebase.database().ref(ref).set(preparedMessage);
        }


    },
    setUserData() {
        if (this.userData == null) {
            if (localStorage.getItem('loggedIn')) {
                this.userData = JSON.parse(localStorage.getItem('userData'));
            } else if (sessionStorage.getItem('loggedIn')) {
                this.userData = JSON.parse(sessionStorage.getItem('userData'));
            }
        }

        return this.userData;
    },
    setDateTime(message) {

        if (!this.timeOffset) {
            this.database().ref("/.info/serverTimeOffset").on('value', (offset) => {
                this.timeOffset = offset.val()
            });
        }

        moment.locale('ar')

        let time = moment(message.timestamp + this.timeOffset - 2000)

        message.dateTime = (Date.now() - (message.timestamp + this.timeOffset)) < (3600000) ? time.fromNow() : time.calendar();

        return message
    },
    initializeUnseen(modal = false, unseenWrapper = true) {

        if (modal) {
            modal.off('shown.bs.modal')
            modal.off('hidden.bs.modal')

            modal.on('shown.bs.modal', () => {
                this.setUnseenCounter(false, unseenWrapper)
                this.unseenState(0, unseenWrapper)
            })

            modal.on('hidden.bs.modal', () => {
                this.unseenCounter = true
            })
        }

        this.unseenState(-1, unseenWrapper)

        this.unseenHandler = typeof (this.unseenHandler) == 'undefined' ? () => {
            this.unseenInc(unseenWrapper)
        } : this.unseenHandler

        this.getMessagesRef().off('child_added', this.unseenHandler);
        this.getMessagesRef().limitToLast(1).on('child_added', this.unseenHandler);
    },
    unseenState(set = false, app = false) {
        if (set !== false) {
            if (typeof app === 'object') {
                app.commit('setUnseen', set)
                this.unseen = app.state.unseen
            } else {
                this.unseen = set
            }
        }

        return this
    },
    unseenInc(app = false) {
        this.checkUnseenCounterStatus(app) && this.unseenState(++this.unseen, app)
    },
    setUnseenCounter(status = true, app = false) {

        this.unseenCounter = status

        if (typeof app === 'object') {
            app.commit('setUnseenCounter', status)
        }
        return this
    },
    checkUnseenCounterStatus(app = false) {
        if (typeof app === 'object') {
            return app.state.unseenCounter
        } else {
            return this.unseenCounter
        }
    },
    destruct() {
        this.loadedMessages = [];
        this.initialized = false;
        clearInterval(this.messagesTimeinterval);
    }
}


export default function chatWith(uid = null) {
    return chat.initialize(uid);
}
